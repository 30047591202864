import React from 'react';

export interface RecycledItems {
    can: number;
}

export interface User {
    id: string;
    username: string;
    email: string;
    gender: 'male' | 'female' | 'other' | 'unknown';
    display_name: string;
    points: {
        [key: string]: number;
    };
    recycled_items: {
        [key: string]: RecycledItems;
    };
    total_points: number;
    total_recycled_items: RecycledItems;
}

export interface Location {
    id: string;
    name: string;
    coords: {
        lat: number;
        long: number;
    };
}

export interface Partner {
    id: string;
    name: string;
    color: string;
}

export interface Product {
    id: string;
    name: string;
    sponsor: string;
    price: number;
    available_units: number;
    image_url: string;
    partner: Partial<Partner>;
}

export interface Purchase {
    id: string;
    status: 'unfulfilled' | 'fulfilled';
    fulfilled_units: number;
    purchased_units: number;
    product: Product;
}

export interface Content {
    home: {
        signin: string;
        register: string;
        signin_with_facebook: string;
    };
    locations: {
        nearest: string;
        location_title: string;
        location_cta: string;
    };
    points: {
        total_points: string;
        add_points: string;
    };
    profile: {
        beginner: string;
        professional: string;
        maestro: string;
        grand_maestro: string;
        genius: string;
        supergenius: string;
        username: string;
        statistics: string;
        recycled_bottles: string;
        energy_saved: string;
        co2_saved: string;
    };
    purchased: {
        no_reservations: string;
    };
    redeem: {
        input_code: string;
        confirm: string;
        err_nocode: string;
        err_invalid: string;
    };
    signin: {
        err_no_email: string;
        err_invalid_email: string;
        err_no_pass: string;
        err_invalid_pass: string;
        login: string;
        register: string;
        forgot_password: string;
        email: string;
        password: string;
        social_login: string;
    };
    forgot_password: {
        err_no_email: string;
        err_invalid_email: string;
        err_unknown: string;
        msg_success: string;
        email: string;
        reset_password: string;
        login: string;
        register: string;
        instructions: string;
        back: string;
    };
    reset_password: {
        err_no_pass: string;
        err_short_pass: string;
        err_no_confirm: string;
        err_no_match: string;
        err_unknown: string;
        msg_success: string;
        msg_invalid_token: string;
        change_password: string;
        login: string;
        instructions: string;
        back: string;
        pass: string;
        confirm_pass: string;
    };
    signup: {
        err_no_username: string;
        err_short_username: string;
        err_invalid_username: string;
        err_no_pass: string;
        err_short_pass: string;
        err_no_confirm: string;
        err_no_match: string;
        err_no_email: string;
        err_invalid_email: string;
        err_try_again: string;
        username: string;
        email: string;
        pass: string;
        confirm_pass: string;
        signup: string;
        has_account: string;
    };
    gift_details: {
        sponsor: string;
        success: string;
        failed: string;
        not_enough_points: string;
        available: string;
        reserve: string;
        unavailable: string;
        are_you_sure: string;
        yes: string;
        no: string;
    };
}

export interface AppContext {
    user: User | null;
    setUser: React.Dispatch<React.SetStateAction<User>>;
    gifts: Product[];
    setGifts: React.Dispatch<React.SetStateAction<Product[]>>;
    partners: Partner[];
    setPartners: React.Dispatch<React.SetStateAction<Partner[]>>;
    purchased: Purchase[];
    setPurchased: React.Dispatch<React.SetStateAction<Purchase[]>>;
    locations: Location[];
    setLocations: React.Dispatch<React.SetStateAction<Location[]>>;
    content: Content | null;
    setContent: React.Dispatch<React.SetStateAction<Content>>;
    activePartner: string;
    setActivePartner: React.Dispatch<React.SetStateAction<string>>;
}

export const AppContext = React.createContext<AppContext>({
    user: null,
    setUser: () => {},
    gifts: [],
    setGifts: () => {},
    partners: [],
    setPartners: () => {},
    purchased: [],
    setPurchased: () => {},
    locations: [],
    setLocations: () => {},
    content: null,
    setContent: () => {},
    activePartner: '',
    setActivePartner: () => {},
});
