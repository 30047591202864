import React from 'react';
import { Button } from './Button';
import shoppingCartImage from '../../assets/images/shopping-cart.png';
import navigationImage from '../../assets/images/map-location.png';
import qrCodeImage from '../../assets/images/qr-button.png';

export const Footer = () => {
    return (
        <div className="c-footer">
            <div className="c-footer__content u-container">
                <div className="u-container">
                    <Button href="/purchased">
                        <img height="30px" width="30px" src={shoppingCartImage} />
                    </Button>
                </div>
                <div className="u-container o-grid__cell--align-start u-pull-vertical-40-percent">
                    <Button href="/redeem">
                        <img height="70px" width="64px" src={qrCodeImage} />
                    </Button>
                </div>
                <div className="u-container">
                    <Button href="/locations">
                        <img height="30px" width="30px" src={navigationImage} />
                    </Button>
                </div>
            </div>
        </div>
    );
};
