import { A, navigate } from 'hookrouter';
import React, { useContext } from 'react';
import solagroLogo from '../../assets/images/logo-login-screen.png';
import facebookLogo from '../../assets/images/facebook.png';
import googleLogo from '../../assets/images/google.png';
import eu4logo from '../../assets/images/eu4.png';
import greenGrowthLogo from '../../assets/images/greengrowth.png';
import { Button } from '../components/Button';
import client from './../api/ApiInstance';
import { AppContext } from '../AppContext';
import { en } from "../i18n/en";
import { rs } from "../i18n/rs";
import { LoadingScreen } from './LoadingScreen';
import { useValidateAuth } from '../hooks/api';


const HomeScreen = () => {
    const isLoading = useValidateAuth({ onSuccess: '/gifts', initialLoading: true });
    const context = useContext(AppContext);

    if (isLoading) {
        return <LoadingScreen />;
    }

    return (
        <div className="u-container-column u-login-background">
            <img src={solagroLogo} width="200px" height="200px" style={{ marginTop: 'auto' }} />
            <Button
                className="c-btn c-btn--text u-vertical-margin-small"
                style={{
                    marginTop: '40px',
                    width: '60%',
                    minWidth: 280,
                    height: '54px',
                    borderRadius: '10',
                    color: '#222C46',
                    backgroundColor: '#fff',
                }}
                type="button"
                onClick={() => navigate('/signin')}
                color="white"
            >
                {context.content.home.signin}
            </Button>
            <Button
                className="c-btn c-btn--text u-vertical-margin-small"
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'row',
                    width: '60%',
                    minWidth: 280,
                    height: '54px',
                    borderRadius: '10',
                    color: '#fff',
                    backgroundColor: '#4267B2',
                    margin: 0,
                }}
                href={`/auth/facebook?ts=${Date.now()}`}
            >
                <svg
                    style={{
                        minWidth: 32,
                        flex: '0',
                        transform: 'translateX(-0.25em)',
                    }}
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 96.1 96.1"
                >
                    <path
                        fill="#fff"
                        d="M72 0H59.7c-14 0-23 9.3-23 23.7v10.9H24c-1 0-2 .8-2 2v15.7c0 1.1 1 2 2 2h12.6v39.9c0 1 .8 2 2 2h16.3c1 0 2-1 2-2v-40h14.6c1 0 2-.8 2-1.9V36.5a2 2 0 0 0-2-2H56.8v-9.2c0-4.4 1.1-6.7 6.9-6.7H72c1 0 2-.9 2-2V2c0-1.1-1-2-2-2z"
                    />
                </svg>
                {context.content.home.signin_with_facebook}
            </Button>
            <A style={{ color: '#fff', fontWeight: '300', marginTop: '40px' }} href="/signup">
                {context.content.home.register}
            </A>
            <div
                style={{
                    height: '135px',
                    marginBottom: '10%',
                    marginTop: 'auto',
                    backgroundColor: 'white',
                    overflow: 'hidden',
                    alignItems: 'center',
                    padding: '20px',
                    opacity: '0'
                }}
            >
                {/* <p style={{ marginBottom: 5, textAlign: 'center' }}>Financed by</p>
                <img src={greenGrowthLogo} width="50%" />
                <img src={eu4logo} width="20%" style={{ float: 'right' }} /> */}
            </div>
        </div>
    );
};

export default HomeScreen;
