export const en = {
    home: {
        signin: 'LOG IN',
        register: 'REGISTER THROUGH E-MAIL',
        signin_with_facebook: 'Log in with Facebook'
    },
    locations: {
        nearest: 'Closest location:',
        location_title: 'The Circular Lab – ECOEMBES',
        location_cta: 'START NAVIGATION',
    },
    points: {
        total_points: 'Total points',
        add_points: 'ADD POINTS',
    },
    profile: {
        beginner: 'Beginner',
        professional: 'Professional',
        maestro: 'Maestro',
        grand_maestro: 'Grand Maestro',
        genius: 'Genius',
        supergenius: 'SUPERGENIUS',
        username: 'Username',
        statistics: 'Statistics',
        recycled_bottles: 'Recycled cans',
        energy_saved: 'Energy saved in KW',
        co2_saved: `Less CO2 in Kg`,
    },
    purchased: {
        no_reservations: "You haven't reserved any gifts yet",
    },
    redeem: {
        input_code: 'Input the code from the press and confirm',
        confirm: 'Confirm',
        err_nocode: 'You have to input the code.',
        err_invalid: 'Code is invalid!',
    },
    signin: {
        err_no_email: 'Please enter your e-mail address',
        err_invalid_email: 'Invalid e-mail address',
        err_no_pass: 'Please enter your password',
        err_invalid_pass: 'Invalid e-mail or password',
        login: 'Log in',
        register: 'Not registered yet? Register here.',
        forgot_password: 'Forgot your password?',
        email: 'E-mail address',
        password: 'Password',
        social_login: 'Other log in options',
    },
    forgot_password: {
        err_no_email: 'Please enter your e-mail address',
        err_invalid_email: 'Invalid e-mail address',
        err_unknown: 'Unknown error. Please try again.',
        msg_success:
            'If there is an account with the provided e-mail address you will get further instructions via e-mail message.',
        email: 'E-mail address',
        reset_password: 'Reset password',
        register: 'Not registered yet? Register here.',
        login: 'Log in',
        instructions: 'Please enter an e-mail address you have used to register and we will send you the message with password reset instructions.',
        back: 'Go back',
    },
    reset_password: {
        err_no_pass: 'Please enter your password',
        err_short_pass: 'Password has to be longer than 5 characters',
        err_no_confirm: 'Please confirm your password',
        err_no_match: 'Passwords do not match',
        err_unknown: 'Unknown error. Please try again.',
        msg_success: 'You have successfully changed your password. Now you can sign in using the new password.',
        msg_invalid_token: 'This link is no longer valid. Please request a new password reset link.',
        change_password: 'Change password',
        login: 'Log in',
        instructions: 'Please enter new password',
        back: 'Go back',
        pass: 'Password',
        confirm_pass: 'Confirm Password',
    },
    signup: {
        err_no_username: 'Please enter your username',
        err_short_username: 'Username needs to be longer than 4 characters',
        err_invalid_username: 'Username contains invalid characters',
        err_no_pass: 'Please enter your password',
        err_short_pass: 'Password has to be longer than 5 characters',
        err_no_confirm: 'Please confirm your password',
        err_no_match: 'Passwords do not match',
        err_no_email: 'Please enter your e-mail',
        err_invalid_email: 'Invalid e-mail address',
        err_try_again: 'Error, try again',
        username: 'Username',
        email: 'E-mail',
        pass: 'Password',
        confirm_pass: 'Confirm Password',
        signup: 'Register',
        has_account: 'Already have an account? Log in.',
    },
    gift_details: {
        sponsor: 'Reward provided by:',
        success: 'Your purchase was successful!',
        failed: 'Purchase failed. Please try again',
        not_enough_points: 'Not enough points!',
        available: 'Available quantity:',
        reserve: 'RESERVE REWARD',
        unavailable: 'The reward you are looking for is unavailable at the moment!',
        are_you_sure: 'Are you sure you want to spend %s points on this reward?',
        yes: 'Yes',
        no: 'No',
    },
};
