import React, { useContext } from 'react';
import { Button } from './Button';
import { AppContext } from '../AppContext';
import trophyImage from '../../assets/images/nagrade.png';
import avatarImage from '../../assets/images/avatars.png';
import coinsImage from '../../assets/images/coins.png';
import homeImage from '../../assets/images/home.png';

export const Header = () => {
    const context = useContext(AppContext);
    const {user, activePartner} = context;
    const activePoints = activePartner === '' || !user || !user.points || typeof user.points[activePartner] === 'undefined'
        ? (user ? user.total_points : 0)
        : user.points[activePartner];
    return (
        <div className="c-header">
            <div className="c-header__content o-grid o-grid--auto o-grid--flush o-grid--align-center">
                <div className="o-grid__cell u-overflow-text-container">
                    <Button href="/profile" className="u-small-right-margin">
                        <img
                            height="60"
                            width="60"
                            src={user.avatar_url || avatarImage}
                            className="u-circle u-small-border"
                        />
                    </Button>
                    <span className="c-header__title">
                        {context.user ? context.user.display_name : 'Solagro'}
                    </span>
                </div>
                <Button href="/points" disabled={true}>
                    <img height="32" width="32" src={coinsImage} />
                    <span className="c-btn__label">
                        {activePoints || 0}
                    </span>
                </Button>
                <Button href="/points">
                    <img height="32" width="32" src={trophyImage} />
                </Button>
                <Button href="/gifts">
                    <img height="32" width="32" src={homeImage} />
                </Button>
            </div>
        </div>
    );
};
